@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  padding-left: 48px;
  padding-right: 48px;
}

.Home {
  width: 100%;
}

.inset-0 {
  width: 100%;
  height: 100%;
}


.tut {
  background: #00ffc2;
  padding: 3px 10px;
  position: relative;
  top: -7px;
  color: #061b20;
  right: 4px;
  cursor: pointer;
  transition: all 300ms;
}

.tut:hover {
  background: white;
}

.tutorial {
  z-index: 10000000;
  background: #031216ed;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms;
}

.tutorial.active {
  opacity: 1;
  pointer-events: all;
  transition: all 300ms 1000ms;
}

.tutorial_screen {
  height: 300px;
  width: 700px;
  pointer-events: none;
}

.tutorial_screen.active {
  pointer-events: all;
}

.tutorial_screen.active .gif {
  transform: scale(1) translateY(-119px) translateX(-153px);
  opacity: 1;
  transition: all 250ms 250ms;
}

.tutorial_screen.active .content {
  opacity: 1;
  transform: scale(1);
  transition: all 250ms 300ms;
}

.tutorial_screen .gif {
  width: 400px;
  opacity: 0;
  box-shadow: 0 0 0 4px white;
  height: 400px;
  position: absolute;
  transition: all 250ms 50ms;
  z-index: 1;
  transform: scale(0) translateY(-119px) translateX(-153px);
}

.tutorial_screen .content {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 84%, 92% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 84%, 92% 100%, 0 100%);
  opacity: 0;
  background: #072931;
  padding: 60px 60px 60px 280px;
  transition: all 250ms 0ms;
  transform: scale(0);
  color: white;
}

.tutorial_screen .content h2 {
  font-size: 28px;
  margin-bottom: 8px;
}

.tutorial_screen .content p {
  font-size: 14px;
  line-height: 18px !important;
}

.diffSelector {
  padding-bottom: 20px;
  width: 327px;
  border-bottom: 2px solid #0e2328;
}

.difficulty {
  background: #041316;
  font-size: 16px;
  padding: 6px 10px;
  width: 33.33%;
  opacity: 0.5;
  transition: all 200ms;
  cursor: pointer;
}

.difficulty:hover {
  opacity: 1;
}

.difficulty.active {
  background: #00ffc2;
  color: #061b20;
  opacity: 1;
}

.bought {
  background: #051418d9;
  padding: 10px;
  color: #ffffff;
  width: 90%;
  left: 7px;
  font-weight: bold;
  padding: 89px 10px;
  border-radius: 10px;
  top: 50%;
  font-size: 18px;
  text-align: center;
  transform: translateY(-50%);
}

.text-blue {
  color: #061B20;
}

.text-pink {
  color: #E82755;
}

.text-brightblue {
  color: #00D1FF;
}

.text-green {
  color: #00FFC2;
}

.bg-grey {
  background: #102B32;
}

.bg-green {
  background: #00FFC2;
}

.text-description {
  color: #91C2CE;
  font-size: 13px;
  line-height: 16px !important;
}

.bg-blue {
  background: #061B20;
}

.text-lightblue {
  color: #092830;
}

.text-lightgrey {
  color: #35464A;
}

.text-lime {
  color: #E5FF44;
}

.line-height-small {
  line-height: 16px;
}

.card--hacker {
  background-image: url("https://assets.codepen.io/217233/hack--hackercardback.png");
  pointer-events: none;
}

.card--firewall {
  background-image: url("https://assets.codepen.io/217233/hack--cardbackempty.png");
}

.card--firewall--active {
  background-image: url("https://assets.codepen.io/217233/hack--firewallcardback_1.png");
  -webkit-animation: pump 200ms forwards;
  animation: pump 200ms forwards;
}

.card--defensive {
  background-image: url("https://assets.codepen.io/217233/hack--firewallcardback_1.png");
}

.card--enemy {
  background-image: url("https://assets.codepen.io/217233/hack--enemycardback.png");
}

.card--format {
  background-image: url("https://assets.codepen.io/217233/hack--formatcardback.png");
}

.card--node {
  background-image: url("https://assets.codepen.io/217233/hack--datacardback.png");
}

.card--mine,
.card--healing,
.card--undefined {
  background-image: url("https://assets.codepen.io/217233/hack--genericcardback.png");
}

.card--offensive {
  background-image: url("https://assets.codepen.io/217233/hack--offensivecardback.png");
}

.card--relic {
  background-image: url("https://assets.codepen.io/217233/hack--upgradecardback.png");
}

.animationWrap {
  height: 215px;

}

.animationWrap.damaged {
  -webkit-animation: shake 200ms forwards;
  animation: shake 200ms forwards;
}

.shake {
  -webkit-animation: shake 200ms forwards;
  animation: shake 200ms forwards;
}

.trimWrap {
  left: -67px;
  top: -44px;
}

@-webkit-keyframes pump {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pump {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(0);
  }
}

@-webkit-keyframes arrow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes arrow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.eject {
  transition: all 200ms;
}

.eject:hover {
  bottom: 8px;
}

@-webkit-keyframes move {
  0% {
    transform: translateX(0) translateY(0);
  }

  50% {
    transform: translateX(100px) translateY(100px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

@keyframes move {
  0% {
    transform: translateX(0) translateY(0);
  }

  50% {
    transform: translateX(100px) translateY(100px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

@-webkit-keyframes in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.15;
  }
}

@keyframes in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.15;
  }
}


.hack--pattern {
  opacity: 0;
  right: -2700px;
  top: -900px;
  transition: all 1400ms cubic-bezier(0.58, 0, 0.07, 1.01);
  width: 3974.17px;
  -webkit-animation: in 2s 400ms forwards;
  animation: in 2s 400ms forwards;
}

.hack--pattern.notBlurred {
  pointer-events: none;
  filter: blur(0px);
}

.hack--pattern img {
  max-width: auto;
  -webkit-animation: move 24s linear infinite;
  animation: move 24s linear infinite;
}

.hack--pattern.active {
  right: 800px;
  top: -400px;
}

.eq {
  width: 2px;
  height: 10px;
  margin: 0 1px;
  transform: scaleY(0.1);
  transform-origin: 0 100%;
}

.eq.active:nth-of-type(1) {
  -webkit-animation: eq 1s 0.2s infinite;
  animation: eq 1s 0.2s infinite;
}

.eq.active:nth-of-type(2) {
  -webkit-animation: eq 1s 0.4s infinite;
  animation: eq 1s 0.4s infinite;
}

.eq.active:nth-of-type(3) {
  -webkit-animation: eq 1s 0.6s infinite;
  animation: eq 1s 0.6s infinite;
}

.eq.active:nth-of-type(4) {
  -webkit-animation: eq 1s 0.8s infinite;
  animation: eq 1s 0.8s infinite;
}

@-webkit-keyframes eq {
  0% {
    transform: scaleY(0.2);
  }

  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(0.7);
  }

  100% {
    transform: scaleY(0.2);
  }
}

@keyframes eq {
  0% {
    transform: scaleY(0.2);
  }

  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(0.7);
  }

  100% {
    transform: scaleY(0.2);
  }
}

.introWrapper.active {
  pointer-events: none !important;
}

.introWrapper.active div {
  pointer-events: none !important;
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.deckLimit {
  color: #e82755;
  font-size: 16px;
  background: #e8275526;
  padding: 10px 30px;
  -webkit-animation: flashit 2s infinite;
  animation: flashit 2s infinite;
  opacity: 1;
}

@-webkit-keyframes flashit {
  5% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  15% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flashit {
  5% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  15% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }

}

.arrows img {
  max-width: 30px;
  opacity: 0.06;
}

.arrows--three img:nth-of-type(3) {
  -webkit-animation: arrow 1s 0.0833333333s infinite;
  animation: arrow 1s 0.0833333333s infinite;
}

.arrows--three img:nth-of-type(2) {
  -webkit-animation: arrow 1s 0.1666666667s infinite;
  animation: arrow 1s 0.1666666667s infinite;
}

.arrows--three img:nth-of-type(1) {
  -webkit-animation: arrow 1s 0.25s infinite;
  animation: arrow 1s 0.25s infinite;
}

.arrows--five img:nth-of-type(5) {
  -webkit-animation: arrow 1s 0.0833333333s infinite;
  animation: arrow 1s 0.0833333333s infinite;
}

.arrows--five img:nth-of-type(4) {
  -webkit-animation: arrow 1s 0.1666666667s infinite;
  animation: arrow 1s 0.1666666667s infinite;
}

.arrows--five img:nth-of-type(3) {
  -webkit-animation: arrow 1s 0.25s infinite;
  animation: arrow 1s 0.25s infinite;
}

.arrows--five img:nth-of-type(2) {
  -webkit-animation: arrow 1s 0.3333333333s infinite;
  animation: arrow 1s 0.3333333333s infinite;
}

.arrows--five img:nth-of-type(1) {
  -webkit-animation: arrow 1s 0.4166666667s infinite;
  animation: arrow 1s 0.4166666667s infinite;
}

.arrows--five img:nth-of-type(0) {
  -webkit-animation: arrow 1s 0.5s infinite;
  animation: arrow 1s 0.5s infinite;
}

.arrows--five img:nth-of-type(-1) {
  -webkit-animation: arrow 1s 0.5833333333s infinite;
  animation: arrow 1s 0.5833333333s infinite;
}

.arrows--five img:nth-of-type(-2) {
  -webkit-animation: arrow 1s 0.6666666667s infinite;
  animation: arrow 1s 0.6666666667s infinite;
}

.arrows--five img:nth-of-type(-3) {
  -webkit-animation: arrow 1s 0.75s infinite;
  animation: arrow 1s 0.75s infinite;
}

.arrows--five img:nth-of-type(-4) {
  -webkit-animation: arrow 1s 0.8333333333s infinite;
  animation: arrow 1s 0.8333333333s infinite;
}

.arrows--five img:nth-of-type(-5) {
  -webkit-animation: arrow 1s 0.9166666667s infinite;
  animation: arrow 1s 0.9166666667s infinite;
}

.arrows--five img:nth-of-type(-6) {
  -webkit-animation: arrow 1s 1s infinite;
  animation: arrow 1s 1s infinite;
}

.arrows--five img:nth-of-type(-7) {
  -webkit-animation: arrow 1s 1.0833333333s infinite;
  animation: arrow 1s 1.0833333333s infinite;
}

.arrows--five img:nth-of-type(-8) {
  -webkit-animation: arrow 1s 1.1666666667s infinite;
  animation: arrow 1s 1.1666666667s infinite;
}

.arrows--five img:nth-of-type(-9) {
  -webkit-animation: arrow 1s 1.25s infinite;
  animation: arrow 1s 1.25s infinite;
}

.arrows--five img:nth-of-type(-10) {
  -webkit-animation: arrow 1s 1.3333333333s infinite;
  animation: arrow 1s 1.3333333333s infinite;
}

.arrows--five img:nth-of-type(-11) {
  -webkit-animation: arrow 1s 1.4166666667s infinite;
  animation: arrow 1s 1.4166666667s infinite;
}

.arrows--five img:nth-of-type(-12) {
  -webkit-animation: arrow 1s 1.5s infinite;
  animation: arrow 1s 1.5s infinite;
}

.arrows--five img:nth-of-type(-13) {
  -webkit-animation: arrow 1s 1.5833333333s infinite;
  animation: arrow 1s 1.5833333333s infinite;
}

.arrows--five img:nth-of-type(-14) {
  -webkit-animation: arrow 1s 1.6666666667s infinite;
  animation: arrow 1s 1.6666666667s infinite;
}

.arrows--five img:nth-of-type(-15) {
  -webkit-animation: arrow 1s 1.75s infinite;
  animation: arrow 1s 1.75s infinite;
}

.arrows--five img:nth-of-type(-16) {
  -webkit-animation: arrow 1s 1.8333333333s infinite;
  animation: arrow 1s 1.8333333333s infinite;
}

.arrows--five img:nth-of-type(-17) {
  -webkit-animation: arrow 1s 1.9166666667s infinite;
  animation: arrow 1s 1.9166666667s infinite;
}

.arrows--five img:nth-of-type(-18) {
  -webkit-animation: arrow 1s 2s infinite;
  animation: arrow 1s 2s infinite;
}

.arrows--five img:nth-of-type(-19) {
  -webkit-animation: arrow 1s 2.0833333333s infinite;
  animation: arrow 1s 2.0833333333s infinite;
}

.arrows--five img:nth-of-type(-20) {
  -webkit-animation: arrow 1s 2.1666666667s infinite;
  animation: arrow 1s 2.1666666667s infinite;
}

.arrows--five img:nth-of-type(-21) {
  -webkit-animation: arrow 1s 2.25s infinite;
  animation: arrow 1s 2.25s infinite;
}

.arrows--five img:nth-of-type(-22) {
  -webkit-animation: arrow 1s 2.3333333333s infinite;
  animation: arrow 1s 2.3333333333s infinite;
}

.arrows--five img:nth-of-type(-23) {
  -webkit-animation: arrow 1s 2.4166666667s infinite;
  animation: arrow 1s 2.4166666667s infinite;
}

.arrows--five img:nth-of-type(-24) {
  -webkit-animation: arrow 1s 2.5s infinite;
  animation: arrow 1s 2.5s infinite;
}

.arrows--five img:nth-of-type(-25) {
  -webkit-animation: arrow 1s 2.5833333333s infinite;
  animation: arrow 1s 2.5833333333s infinite;
}

.arrows--five img:nth-of-type(-26) {
  -webkit-animation: arrow 1s 2.6666666667s infinite;
  animation: arrow 1s 2.6666666667s infinite;
}

.arrows--five img:nth-of-type(-27) {
  -webkit-animation: arrow 1s 2.75s infinite;
  animation: arrow 1s 2.75s infinite;
}

.arrows--five img:nth-of-type(-28) {
  -webkit-animation: arrow 1s 2.8333333333s infinite;
  animation: arrow 1s 2.8333333333s infinite;
}

.arrows--five img:nth-of-type(-29) {
  -webkit-animation: arrow 1s 2.9166666667s infinite;
  animation: arrow 1s 2.9166666667s infinite;
}

.game_shop__cards .slot,
.game_stageComplete .slot {
  transition: all 200ms !important;
}

.game_shop__cards .slot:hover,
.game_stageComplete .slot:hover {
  transform: translateY(-10px);
}

.game_enumerate,
.game_gameoverman,
.game_winner {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background: #041b20f2;
  transition: all 300ms;
}

.game_enumerate.active,
.game_gameoverman.active,
.game_winner.active {
  opacity: 1;
  pointer-events: all;
}

.game_gameoverman,
.game_winner {
  background: #041b20;
  transition: all 1000ms 1000ms;
}

.game_gameoverman.active,
.game_winner.active {
  transition: all 400ms;
}

.game_intro {
  opacity: 0;
  transition: opacity 1000ms;
  background: #061b20;
  pointer-events: none;
}

.game_intro.active {
  opacity: 1;
  pointer-events: all;
}

.game_intro h3.active {
  -webkit-animation: flash 500ms forwards;
  animation: flash 500ms forwards;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.game_intro__achievements {
  transition: transform 600ms 0ms cubic-bezier(0.55, 0.01, 0.01, 0.97);
  position: absolute;
  transform: translateX(100%);
  left: 0;
  right: 0;
  width: 100%;
  background: #0F272D;
}

.game_intro__achievements.active {
  transition: transform 600ms 250ms cubic-bezier(0.55, 0.01, 0.01, 0.97);
  transform: translateX(0%);
}

.game_intro__achievements.active .inner {
  opacity: 1;
  transition: all 600ms 600ms;
}

.game_intro__achievements .inner {
  max-width: 1250px;
  margin: auto;
  padding: 30px;
}

.game_intro__achievements .inner ::-webkit-scrollbar {
  width: 3px;
}

.game_intro__achievements .inner ::-webkit-scrollbar-track {
  background: #0d2329;
}

.game_intro__achievements .inner ::-webkit-scrollbar-thumb {
  background-color: #00fcc0;
}

.game_intro__achievements .inner .grid {
  height: calc(100vh - 392px);
  margin-bottom: 24px;
}

.ach {
  transition: all 100ms;
  cursor: pointer;
}

.ach:hover {
  opacity: 1;
}

.game_intro__newgame {
  width: 0;
  transition: all 600ms 600ms;
}

.game_intro__newgame .inner {
  opacity: 0;
  padding: 90px 0 90px 90px;
  border-left: 2px solid #0e2328;
  transition: width 600ms 0ms, opacity 600ms 0ms, margin 1ms 600ms;
}

.game_intro__newgame.active {
  width: 500px;
  transition: all 600ms 250ms;
}

.game_intro__newgame.active .inner {
  margin-left: 90px;
  opacity: 1;
  transition: opacity 600ms 600ms, width 600ms 600ms;
}

.game_intro__newgame input {
  color: #00FFC2;
  background: #0F272D;
  padding: 8px 17px 8px 47px;
  font-size: 20px;
  outline: none;
  margin-top: 4px;
}

.game_intro__newgame button.random {
  transition: all 200ms;
}

.game_intro__newgame button.random:hover {
  transform: rotate(180deg);
}

.game_intro__newgame .net {
  top: 16px;
}

button.hack {
  color: #061b20;
  padding: 6px 30px;
  font-size: 18px;
  margin-top: 20px;
  transition: all 200ms;
  font-weight: 500;
  -webkit-clip-path: polygon(18% 0, 100% 0, 100% 67%, 84% 100%, 0 100%, 0 35%);
  clip-path: polygon(18% 0, 100% 0, 100% 67%, 84% 100%, 0 100%, 0 35%);
}

button.hack:hover {
  background: #00ffc2;
}

button.skip {
  -webkit-clip-path: polygon(14% 0, 100% 0, 100% 70%, 86% 100%, 0 100%, 0 33%);
  clip-path: polygon(14% 0, 100% 0, 100% 70%, 86% 100%, 0 100%, 0 33%);
}

.backArrow {
  opacity: 0.2;
  transition: all 200ms;
  cursor: pointer;
}

.backArrow:hover {
  opacity: 1;
}

.game_achievement {
  background: #021114;
  padding: 23px 40px 20px 40px;
}

.game_achievement h3 {
  text-transform: uppercase;
  font-size: 12px;
  color: #00FCC0;
}

.game_achievement__name {
  font-size: 22px;
}

.game_achievement__description {
  font-size: 16px;
  color: #607a81;
  line-height: 18px;
}

.selection {
  transition: all 300ms;
  padding: 30px 30px;
  background: #05181c;
}

.selection:hover {
  background: #00ffc2;
}

.selection:hover h4,
.selection:hover p {
  color: #061b20;
}

.selection:hover svg path {
  fill: #061b20;
}

.detected {
  background: #00ffa308;
  padding: 0px 60px;
  margin: 30px 0;
}

.menu h3,
.menu a,
.game_gameover h3,
.game_gameover a,
.game_gameoverman h3,
.game_gameoverman a,
.game_winner h3,
.game_winner a {
  cursor: pointer;
  transition: all 150ms;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 20px;
  display: block;
}

.menu h3:hover,
.menu a:hover,
.game_gameover h3:hover,
.game_gameover a:hover,
.game_gameoverman h3:hover,
.game_gameoverman a:hover,
.game_winner h3:hover,
.game_winner a:hover {
  background: #00FFC2;
  color: #061b20;
}

.menu a,
.game_gameover a,
.game_gameoverman a,
.game_winner a {
  padding: 0;
}

.game_achievement {
  transform: translateY(100%);
  transition: all 300ms;
}

.game_achievement.active {
  transform: translateY(0%);
}

body {
  font-size: 14px;
  background: #061B20;
  font-family: "Rajdhani", sans-serif;
  overflow: hidden;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p {
  line-height: 100% !important;
  color: white;
}

.constrain {
  min-width: 1250px;
}

.screenCap {
  z-index: 10000000;
  width: 100%;
  height: 100%;
  background: #061b20;
  display: none;
}


@media screen and (max-width: 1300px) {
  .screenCap {
    display: flex;
  }
}
@media screen and (max-height: 700px) {
  .screenCap {
    display: flex;
  }
}

@media screen and (max-height: 700px) {
  .gamePreload {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .gamePreload {
    display: none;
  }
}
@media screen and (max-height: 700px) {
  .game_intro__achievements {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .game_intro__achievements {
    display: none;
  }
}
@media screen and (max-height: 700px) {
  .game_achievement {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .game_achievement {
    display: none;
  }
}



.game_stage__relics {
  position: absolute;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 50px 50px 50px 50px;
  background: #051418;
  transform: translateY(100%);
  transition: transform 300ms, opacity 300ms 0s;
  opacity: 0;
}

.game_stage__relics.active {
  transition: transform 300ms, opacity 300ms 1s;
  opacity: 1;
}

.game_stage__relics .relic {
  background: #071b20;
  padding: 20px 16px;
  border-radius: 14px;
}

.game_stage__relics .relic svg {
  height: 18px;
}

.game_stage__relics.min {
  transform: translateY(0%);
}

.count {
  background: #00ffc2;
  height: 24px;
  width: 38px;
  font-weight: 700;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  color: #061b20;
  border-radius: 100px;
  position: absolute;
  top: -31px;
  right: -26px;
}

.game_header {
  z-index: 10000;
  position: relative;
  transition: all 600ms 600ms;
  transform: translateY(-200px);
  width: 100%;
  height: 75px;
}

.game_header.active {
  transform: translateY(0px);
  width: 100%;
  height: 75px;
}

.game_stage {
  max-width: 1250px;
  opacity: 0;
  transition: opacity 1s 1s;
}

.game_stage.active {
  opacity: 1;
}

.game .slot {
  width: 135px;
  height: 215px;
  transition: transform 600ms, opacity 600ms, width 150ms 100ms, margin 150ms 100ms;
}

.game .slot.inactive {
  transform: scale(0);
  opacity: 0;
  width: 0;
  margin-right: -16px;
}

.game .slot.inactive .back {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.game .slot.unrevealed {
  background: #2c2c2c;
  color: white;
}

.game .cardholder {
  transform: rotateY(0deg);
  transition: transform 0.4s 300ms, right 50ms 50ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.game .cardholder--inactive {
  transform: rotateY(180deg);
}

.game .card {
  height: 215px;
  width: 135px;
  border-radius: 6px;
  cursor: pointer;
  background-size: cover;
  color: white;
}

.game .card.trash {
  background-image: url(https://assets.codepen.io/217233/cardBackTrash.png);
  pointer-events: none;
}

.game .card.back {
  background-image: url(https://assets.codepen.io/217233/hack--cardback.png);
  transform: rotateY(0deg);
  transition: transform 0.4s 300ms;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.game .card.back.inactive {
  transform: rotateY(180deg);
}
.damageNumber{
  opacity: 0;
  /* -webkit-animation: damageNumber 350ms forwards;
  animation: damageNumber 350ms forwards; */
}
.damageNumberHit{
  opacity: 1;
  -webkit-animation: damageNumberHit 350ms forwards;
  animation: damageNumberHit 350ms forwards;
}
.game .damageNumber {
  /* opacity: 0.1; */
  -webkit-animation: damageNumber 350ms forwards;
  animation: damageNumber 350ms forwards;
}

@-webkit-keyframes damageNumber {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }

  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes damageNumber {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }

  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
@-webkit-keyframes damageNumberHit {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }

  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes damageNumberHit {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }

  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.gsc_player__character,
.cardholder {
  transition: all 50ms;
  height: 215px;
}

.relicCount {
  background: #00ffc2;
  height: 24px;
  width: 24px;
  font-weight: 700;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  color: #061b20;
  border-radius: 100px;
  position: relative;
  top: -1px;
  left: 2px;
}

.game_minimize,
.relics_minimize {
  width: 220px;
  background: #051418;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
  transition: all 300ms;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  -webkit-clip-path: polygon(0% 0, 100% 0, 100% 67%, 91% 100%, 0 100%, 0 35%);
  clip-path: polygon(0% 0, 100% 0, 100% 67%, 91% 100%, 0 100%, 0 35%);
}

.game_minimize:hover,
.relics_minimize:hover {
  color: #00ffc2;
}

.relics_minimize {
  bottom: auto;
  top: -120px;
  left: 0;
  width: 160px;
  right: auto;
  margin: auto;
  -webkit-clip-path: polygon(90% 0, 100% 21%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(90% 0, 100% 21%, 100% 100%, 0 100%, 0 0);
}

@-webkit-keyframes pulseProgress {
  from {
    box-shadow: 0 0 0 0px #00ffc2;
  }

  to {
    box-shadow: 0 0 0 5px rgba(0, 255, 194, 0);
  }
}

@keyframes pulseProgress {
  from {
    box-shadow: 0 0 0 0px #00ffc2;
  }

  to {
    box-shadow: 0 0 0 5px rgba(0, 255, 194, 0);
  }
}

.gsc_field {
  min-width: 500px;
}

.progress {
  margin-right: 1px;
}

.progress.active {
  background: #00FFC2;
  -webkit-animation: pulseProgress 1s infinite;
  animation: pulseProgress 1s infinite;
}

.progress.active svg path {
  fill: #061b20;
}

.progress.inactive {
  background: transparent;
  border: 2px solid #053935;
}

.progress.complete {
  background: #00ffc226;
}

.game_shop,
.game_stageComplete {
  background: #051418;
  padding: 60px 0 60px 0;
  transform: translateY(-120%);
  transition: all 300ms;
  z-index: 2000;
}

.game_shop button.hack,
.game_stageComplete button.hack {
  -webkit-clip-path: polygon(8% 0, 100% 0, 100% 70%, 92% 100%, 0 100%, 0 33%);
  clip-path: polygon(8% 0, 100% 0, 100% 70%, 92% 100%, 0 100%, 0 33%);
}

.game_shop.active,
.game_stageComplete.active {
  transform: translateY(0%);
}

.game_shop.min,
.game_stageComplete.min {
  transform: translateY(calc(-100% + 107px));
}

.game_shop.min button,
.game_stageComplete.min button {
  display: none;
}

.game_stageComplete {
  padding: 60px 0 110px 0;
}

.gameInit {
  opacity: 0;
  pointer-events: none;
}

.gameInit .flash {
  z-index: 10000;
  pointer-events: none;
}

.gameInit .menu,
.gameInit .author,
.gameInit .flash {
  opacity: 0;
  transition: all 1000ms 2200ms;
}

.gameInit .author {
  transition: all 1000ms 2000ms;
}

.gameInit .logo {
  transform: scale(10);
  transition: all 100ms 900ms;
}

.gameInit .logo img {
  opacity: 0;
  transition: all 200ms 900ms;
}

.gameInit.active {
  pointer-events: all;
  opacity: 1;
}

.gameInit.active .menu,
.gameInit.active .author {
  opacity: 1;
}

.gameInit.active .flash {
  -webkit-animation: introflash 200ms 1000ms forwards;
  animation: introflash 200ms 1000ms forwards;
}

.gameInit.active .logo {
  transform: scale(1);
}

.gameInit.active .logo img {
  opacity: 1;
}

@-webkit-keyframes introflash {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes introflash {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.gamePreload {
  opacity: 0;
  transition: all 100ms;
  pointer-events: none;
  z-index: 10000000000;
}

.gamePreload.active {
  pointer-events: all;
  opacity: 1;
}

.header_div {
  width: 360px;
  height: 23px;
}

.map_road {
  margin-left: 138px;
  margin-right: auto;
  width: 300px;

}

.tutorial_div {
  position: absolute;
  right: 0px;
}

.ghl {
  width: 106px;
  height: 23px;
}

.ghs {
  width: 238px;
  height: 22px;
}

.gs_text {
  display: flex;
  gap: 0.2rem;
}

.gsc_player {
  width: 338px;
  height: 252px;
}

.left {
  width: 135px;
  height: 252px;
}

.midle {
  width: 36px;
  height: 252px;
  display: flex;
}

.right {
  width: 135px;
  height: 252px;

}

.slot {
  height: 100%;
  width: 100%;
  padding: 0px;
}

.green_border {
  /* top: 4px; */

}

.gsc_pl {
  padding: 0px;
}



.gsb {}

.tech_deck {
  height: 215px;
  width: 135px;
}

.body_deck {
  top: -45px
}

.card_defrag {
  width: 135px;
  height: 215px;
}



.card_drag {
  width: 135px;
  height: 215px;
}

.text-md {
  color: white;
}

.text_grey {
  color: #35464A;
}



.front_card {
  height: 215px;
  width: 135px;
  transform: rotateY(180deg);
  /* transform: rotateY(0deg); */
  transition: transform 0.4s 300ms, right 50ms 50ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}

.front_card_click {
  height: 215px;
  width: 135px;
  transform: rotateY(0deg);
  /* transform: rotateY(180deg); */
  transition: transform 0.4s 300ms, left 50ms 50ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card_text {
  display: flex;
}

.text_proxy {
  color: #061B20;
}

.rotate_card {
  /* display: flex;
  transform: rotateY(180deg);
  transition: transform 0.4s 300ms, right 50ms 50ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; */
}

.back_card {
  background-image: url(https://assets.codepen.io/217233/hack--cardback.png);
  /* pointer-events: none; */
  height: 215px;
  width: 135px;
  border-radius: 6px;
  cursor: pointer;
  background-size: cover;
  color: white;
  margin-top: 5px;
}

.back_card_test {
  background-image: url(https://assets.codepen.io/217233/hack--cardback.png);
  position: absolute;
  transform: rotateY(0deg);
  transition: transform 0.4s 300ms, left 50ms 50ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 215px;
  width: 135px;
  border-radius: 6px;
  cursor: pointer;
  background-size: cover;
  /* margin-top: 5px; */


}

.back_card_test_click {
  background-image: url(https://assets.codepen.io/217233/hack--cardback.png);
  position: absolute;
  transform: rotateY(360deg);
  transition: transform 0.4s 300ms, left 50ms 50ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 215px;
  width: 135px;
  border-radius: 6px;
  cursor: pointer;
  background-size: cover;
  /* margin-top: 5px; */


}

.cardOne {
  width: '135px';
  transition: all 15s;
  margin-right: 1rem;

}

.table_card {
}

.delete_card {
  margin-right: -1rem;
  visibility: hidden;
  height: 215px;
  width: 135px;
  /* display: none; */
  transform: scale(0);
  opacity: 0;
  /* width: 0;
  height: 0; */
  margin-right: -135px;
  transition:  opacity 600ms, margin 150ms 100ms;
  /* transition: transform 4s 300ms, left 500ms 500ms; */

}
.startLogoHack {
  -webkit-animation: minimizer 2000ms forwards;
  animation: minimizer 2000ms forwards;
  opacity: 1;
  /* transform: scale(1); */
  /* transition: all 100ms 900ms; */
  /* -webkit-animation: test2 500ms forwards;
  animation: test2 500ms forwards; */
}

@-webkit-keyframes minimizer {
  0% {
    transform: scale(5);
    opacity: 0;
  }
  20% {
    transform: scale(4.5);
    opacity: 0;
  }
  30% {
    transform: scale(4);
    opacity: 0;
  }
  40% {
    transform: scale(3.5);
    opacity: 0;
  }  
  50% {
    transform: scale(3);
    opacity: 0;
  }  
  60% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes minimizer {
  0% {
    transform: scale(5);
    opacity: 0;
  }
  20% {
    transform: scale(4.5);
    opacity: 0;
  }
  30% {
    transform: scale(4);
    opacity: 0;
  }
  40% {
    transform: scale(3.5);
    opacity: 0;
  }  
  50% {
    transform: scale(3);
    opacity: 0;
  }  
  60% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.menu{
  -webkit-animation: showing 5000ms forwards;
  animation: showing 5000ms forwards;
  opacity: 1;
}
@-webkit-keyframes showing {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }  
  50% {
    opacity: 0.6;
  }  
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showing {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }  
  50% {
    opacity: 0.6;
  }  
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/* .text-2xl {
  color: white;
} */
/* 
width: 135px;
height: 215px;
transition: transform 600ms, opacity 600ms, width 150ms 100ms, margin 150ms 100ms; */